import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import NavLinksAuthenticated from "./nav-links-authenticated"
import logoImage from "../assets/images/logo.png"

const HeaderAuthenticated = () => {
    const [sticky, setSticky] = useState(false)
    const menuEl = useRef(null)
    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    const handleScroll = () => {
        if (window.scrollY > 70) {
            setSticky(true)
        } else if (window.scrollY < 70) {
            setSticky(false)
        }
    }

    return (
        <header className="site-header header-one">
            <nav
                className={`navbar navbar-expand-lg navbar-light header-navigation stricky  ${
                    sticky === true ? "stricky-fixed" : " "
                }`}
            >
                <div className="container clearfix">
                    <div className="logo-box clearfix">
                        <Link to="/" className="navbar-brand">
                            <div className="content-block"><img height={75} src={logoImage} alt="Awesome alter Text" /> <h3 className="logo-title-first">Fanster</h3><div className="logo-title">.ai</div></div>
                        </Link>
                        <button
                            className="menu-toggler"
                            onClick={e => {
                                e.preventDefault()
                                menuEl.current.classList.toggle("active")
                            }}
                        >
                            <span className="fa fa-bars"></span>
                        </button>
                    </div>

                    <div className="main-navigation" ref={menuEl}>
                        <NavLinksAuthenticated />
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default HeaderAuthenticated
