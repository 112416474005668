import React, { Fragment, useRef } from "react"
import { Link } from "gatsby"
import SubNavToggler from "./sub-nav-toggler"
import { Link as ScrollLink } from "react-scroll"

const NavLinksAuthenticated = () => {
  const subMenuEl = useRef(null);

  const handleClick = (event) => {
        switch (event.target.id) {
            case 'default':
                alert('Your default collection. This is the one which was created from the images you provided during on-boarding.');
                break;
            case 'new':
                alert('Create a new collection by uploading more / different images. Coming soon!');
                break;
        }

        return null;
  }

  return (
    <ul className="one-page-scroll-menu navigation-box" ref={subMenuEl}>
        <li>
            <Link to="/blog">Collections</Link>
            <Fragment>
                <SubNavToggler
                    onClick={e => {
                        subMenuEl.current.querySelectorAll(".sub-menu").forEach(item => {
                            item.classList.remove("active")
                        })

                        let clickedItem = e.currentTarget.parentNode
                        clickedItem.querySelector(".sub-menu").classList.toggle("active")
                    }}
                />
                <ul className="sub-menu">
                    <li>
                        <Link to=""><span id='default' onClick={handleClick}>Default</span></Link>
                    </li>
                    <li>
                        <Link to=""><span id='new' onClick={handleClick}>Create New</span></Link>
                    </li>
                </ul>
            </Fragment>
        </li>
    </ul>
  )
}

export default NavLinksAuthenticated
