import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import logoImage from "../assets/images/logo.png"
const Footer = () => {
  return (
    <>
      <footer className="site-footer">
        <span className="bubble-1"></span>
        <span className="bubble-2"></span>
        <span className="bubble-3"></span>
        <span className="bubble-4"></span>
        <span className="bubble-5"></span>
        <span className="bubble-6"></span>
        <Container>
          <div className="inner-container">
            <Row>
              <Col lg={3} md={6} sm={12}>
                <div className="footer-widget">
                  <div className="widget-title">
                    <h3>Follow</h3>
                  </div>
                  <div className="social-block">
                    <a href="https://twitter.com/fansterai">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com/fansterai/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>

      <div className="bottom-footer text-center">
        <Container>
          <p>
            &copy; Copyright 2023
          </p>
        </Container>
      </div>
    </>
  )
}

export default Footer
